<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="40" style="width: 100%">
        <el-col :span="12">
          <el-divider>升级店长商品</el-divider>
          <el-table :data="shopowner" border>
            <el-table-column prop="id" label="商品编号" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" label="商品名称" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="100" show-overflow-tooltip>
              <template #header>
                <Y_choose_goods @change="shopownerAdd">
                  <el-button>添加商品</el-button>
                </Y_choose_goods>
              </template>
              <template v-slot="s">
                <el-button @click="choose(s.row.id,s.row.level)" type="danger">移出</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <el-divider>升级合伙人商品</el-divider>
          <el-table :data="partner" border>
            <el-table-column prop="id" label="商品编号" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" label="商品名称" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="100" show-overflow-tooltip>
              <template #header>
                <Y_choose_goods @change="partnerAdd">
                  <el-button>添加商品</el-button>
                </Y_choose_goods>
              </template>
              <template v-slot="s">
                <el-button @click="choose(s.row.id,s.row.level)" type="danger">移出</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import Y_choose_goods from "@/components/shop/y_choose_goods.vue";

export default {
  components: {Y_choose_goods},
  name: "manage",
  data() {
    return {
      shopowner: [],
      partner: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    choose(id, level) {
      this.$u.api.shop.plugin.partnership.upgradeGoodsChoose({id, level}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    shopownerAdd(e) {
      this.choose(e.id,2);
    },
    partnerAdd(e){
      this.choose(e.id,3);
    },
    load() {
      this.$u.api.shop.plugin.partnership.upgradeGoodsAll().then(res => {
        this.shopowner = res.filter(e => e.level === 2);
        this.partner = res.filter(e => e.level === 3);
      })
    },
  },
}
</script>

<style scoped>

</style>